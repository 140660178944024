<script>

  import NavBar from "@/components/shared/Navbar.vue";
  import Banner from "@/components/modules/home/Banner.vue";
  import ProductsComp from "@/components/modules/home/Product.vue";
  import AboutComp from "@/components/modules/home/About.vue";
  import FeedbackComp from "@/components/modules/home/Feedback.vue";
  import DoubtComp from "@/components/modules/home/Doubt.vue";
  import Footer from "@/components/shared/Footer.vue";
  import CtaComp from "@/components/modules/home/Cta.vue";

  export default {
    name: 'Home_page',
    components: {CtaComp, Footer, DoubtComp, FeedbackComp, AboutComp, ProductsComp, Banner, NavBar},

}
</script>

<template>
  <Nav-bar/>
  <Banner/>
  <ProductsComp/>
  <AboutComp/>
  <FeedbackComp/>
  <DoubtComp/>
  <CtaComp/>
  <Footer/>
</template>

<style scoped>

</style>