<script>
  export default {
    name: 'uber-icon'
  }
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="70" viewBox="0 0 200 70" fill="none">
    <path d="M11.5064 49.6625C12.3516 51.9078 13.5217 53.8276 15.017 55.4219C16.5123 57.0164 18.2838 58.2365 20.3315 59.0826C22.3794 59.9287 24.6059 60.3516 27.0114 60.3516C29.3518 60.3516 31.5458 59.9123 33.5937 59.0336C35.6416 58.1549 37.4292 56.9185 38.9569 55.3243C40.4846 53.7298 41.6711 51.8264 42.5163 49.6137C43.3615 47.401 43.7839 44.9283 43.7839 42.1949V0.0244141H54.023V68.356H43.8815V62.0109C41.6062 64.4186 38.9243 66.2734 35.8364 67.5752C32.7483 68.8766 29.4168 69.5274 25.8412 69.5274C22.2005 69.5274 18.8038 68.8928 15.6508 67.6238C12.4979 66.3548 9.76731 64.5491 7.45963 62.2061C5.15195 59.8632 3.33149 57.049 1.99888 53.7624C0.666059 50.476 -0.000244141 46.8155 -0.000244141 42.7804V0.0244141H10.2388V42.1949C10.2388 44.9283 10.6613 47.4172 11.5064 49.6625Z" fill="#595D64"/>
    <path d="M71.6724 0.0241699V24.9163C73.9477 22.6387 76.5808 20.8329 79.5711 19.4987C82.5615 18.1645 85.8121 17.4975 89.3225 17.4975C92.9632 17.4975 96.3759 18.1807 99.5615 19.5473C102.747 20.9139 105.51 22.7687 107.85 25.1116C110.191 27.4545 112.043 30.2202 113.409 33.4089C114.774 36.5979 115.456 39.9819 115.456 43.5612C115.456 47.1403 114.774 50.5081 113.409 53.6643C112.043 56.8207 110.191 59.5703 107.85 61.9128C105.51 64.2557 102.747 66.1105 99.5615 67.4771C96.3759 68.8437 92.963 69.5269 89.3225 69.5269C85.8119 69.5269 82.5453 68.8599 79.5224 67.5257C76.4994 66.1917 73.8502 64.3857 71.5749 62.1081V68.3556H61.8235V0.0241699H71.6724ZM72.7937 50.2967C73.6713 52.3793 74.8904 54.2015 76.4506 55.7634C78.0109 57.3252 79.8311 58.5618 81.9114 59.4729C83.9919 60.384 86.2346 60.8395 88.64 60.8395C90.9805 60.8395 93.1907 60.384 95.2709 59.4729C97.3512 58.5618 99.155 57.3255 100.683 55.7634C102.211 54.2017 103.43 52.3793 104.34 50.2967C105.25 48.2143 105.705 45.9692 105.705 43.5612C105.705 41.1533 105.25 38.8918 104.34 36.7768C103.43 34.6617 102.211 32.8234 100.683 31.2615C99.1552 29.6998 97.3514 28.4794 95.2709 27.601C93.1904 26.7225 90.9803 26.283 88.64 26.283C86.2998 26.283 84.0894 26.7223 82.0091 27.601C79.9289 28.4797 78.1086 29.6998 76.5482 31.2615C74.9882 32.8234 73.7529 34.6617 72.8427 36.7768C71.9326 38.8918 71.4775 41.1533 71.4775 43.5612C71.4773 45.9692 71.9162 48.2143 72.7937 50.2967Z" fill="#595D64"/>
    <path d="M121.013 33.5069C122.313 30.3832 124.102 27.6337 126.377 25.2584C128.652 22.8831 131.35 21.0121 134.47 19.6455C137.591 18.2789 140.971 17.5957 144.612 17.5957C148.187 17.5957 151.503 18.2465 154.559 19.5479C157.614 20.8495 160.247 22.6554 162.457 24.9657C164.667 27.2758 166.39 30.0093 167.625 33.1654C168.861 36.3218 169.478 39.7547 169.478 43.4642V46.6856H129.01C129.335 48.703 129.985 50.574 130.96 52.2983C131.935 54.0228 133.154 55.5196 134.617 56.7886C136.079 58.0578 137.737 59.0503 139.59 59.7659C141.443 60.4817 143.409 60.8399 145.49 60.8399C151.406 60.8399 156.184 58.3993 159.824 53.5184L166.943 58.7896C164.473 62.1086 161.417 64.7118 157.777 66.599C154.136 68.4862 150.041 69.4299 145.49 69.4299C141.784 69.4299 138.323 68.7788 135.105 67.4775C131.887 66.1761 129.091 64.3537 126.718 62.0108C124.346 59.6679 122.477 56.9184 121.111 53.7623C119.746 50.6061 119.063 47.1731 119.063 43.4638C119.063 39.9497 119.713 36.6307 121.013 33.5069ZM134.714 29.6509C131.951 31.9613 130.115 35.0362 129.205 38.8758H159.532C158.687 35.0362 156.883 31.9613 154.12 29.6509C151.357 27.3409 148.123 26.1857 144.417 26.1857C140.711 26.1857 137.477 27.3409 134.714 29.6509Z" fill="#595D64"/>
    <path d="M187.908 30.7729C185.828 33.0506 184.787 36.1418 184.787 40.0466V68.3556H174.938V18.571H184.69V24.7209C185.925 22.7035 187.534 21.109 189.517 19.9377C191.499 18.7661 193.856 18.1803 196.587 18.1803H199.999V27.3563H195.904C192.654 27.3565 189.988 28.4952 187.908 30.7729Z" fill="#595D64"/>
  </svg>
</template>

<style scoped>

</style>