<script>
  export default {
    name: 'DoubtComp'
  }
</script>

<template>
  <div class="py-80 gap-lg-80 flex-column gap-40 doubt" id="doubt">
    <div class="px-20">
      <h2 class="text-lg-center text-start">Dúvidas</h2>
      <p class="text-lg-center text-start col-9 col-lg-3 m-lg-auto">Principais dúvidas sobre nossos sistemas personalizados.</p>
    </div>
    <div class="d-flex align-items-center gap-160 col-lg-8 m-auto">
      <img class="d-none d-lg-flex" src="@/assets/images/duvidas-img.png" alt="teclado"/>
      <div class="accordion d-flex flex-column gap-20" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button class="accordion-button p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              Diferença entre tecnologia personalizada e soluções prontas
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">
              Uma tecnologia personalizada é desenvolvida especificamente para atender às necessidades exclusivas da sua empresa, enquanto soluções prontas são desenvolvidas para um público mais amplo e podem precisar de adaptações.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button class="accordion-button collapsed p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              Benefícios específicos de investir em uma tecnologia personalizada
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body">
              A tecnologia personalizada oferece maior eficiência, integração com sistemas existentes, controle total e flexibilidade para atender às demandas exclusivas do seu negócio.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStay Open-headingThree">
            <button class="accordion-button collapsed p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
              Custos associados ao desenvolvimento e manutenção.
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
            <div class="accordion-body">
              Os custos podem variar dependendo da complexidade do projeto, tecnologias utilizadas e requisitos específicos.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingFour">
            <button class="accordion-button collapsed p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
              Tempo para desenvolver e implantar.
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
            <div class="accordion-body">
              O prazo pode variar dependendo da complexidade do projeto. Um cronograma claro será definido durante a fase de projeto.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingFive">
            <button class="accordion-button collapsed p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
              Suporte e manutenção após sistema pronto e implantado.
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
            <div class="accordion-body">
              Após a implementação, oferecemos suporte contínuo e manutenção para garantir o funcionamento eficiente da tecnologia e fazer ajustes conforme necessário.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingSix">
            <button class="accordion-button collapsed p-20" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
              Integração da tecnologia personalizada sistemas prontos.
            </button>
          </h2>
          <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
            <div class="accordion-body">
              A tecnologia personalizada pode ser integrada perfeitamente com os sistemas existentes para garantir uma operação eficiente e sem interrupções.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

  .doubt{
    max-width: 95vw;
    margin: auto;
  }
  .accordion-item{
    border: none;
  }
  .accordion-button:not(.collapsed) {
    color: var(--c3);
    background-color: transparent;
    box-shadow: none
  }
  button{
    border: none;
    border-radius: 0 !important;
    color: var(--c3);
    background: transparent;
  }
  .accordion-item{
    border-radius: 20px !important;
    background: #FAFCFF;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  }
  .accordion-item:hover{
    border-radius: 20px;
    background: #FAFCFF;
    box-shadow: 0px 18px 88px -1px rgba(23, 78, 221, 0.10), 0px 8px 28px -2px rgba(23, 78, 221, 0.50);
  }
  button{
    line-height: normal;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    height: 86px;
  }
</style>