<script>
  import PrimaryButton from "@/components/shared/Button.vue";

  export default {
    name: 'Banner-comp',
    components: {PrimaryButton}
  }
</script>

<template>
  <div class="banner" id="home">
    <div class="d-flex flex-column px-20 px-lg-0 py-40 py-lg-80 justify-content-end  align-items-lg-center h-100 gap-20 gap-lg-40">
      <h1><strong>Criando</strong> a sua solução</h1>
      <primary-button url="https://api.whatsapp.com/send?phone=558001912490&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento!"/>
    </div>
  </div>
</template>

<style scoped>
  h1{
    color: var(--c1)
  }
  strong{
    color: var(--c5)
  }
  .banner{
    background-image: url("../../../assets/images/3585195a39c6f5dfae77ddb32b43adcd.gif");
    background-size: cover;
    background-position: center;
    height: 740px;
    max-height: 100vh;
  }
  @media (max-width: 996px) {
    .banner{
      max-height: calc(100vh - 80px
      ) !important};
  }
</style>