<script>

import PrimaryButton from "@/components/shared/Button.vue";
import FacebookIcon from "@/components/icon/facebook-icon.vue";
import InstagramIcon from "@/components/icon/instagram-icon.vue";

export default {
  name: 'CtaComp',
  components:{
    PrimaryButton, FacebookIcon, InstagramIcon
  }
}
</script>

<template>
  <div class="bg-cta">
    <div class="cta py-80 d-flex flex-column justify-content-end">
      <div class="col-lg-8 mx-auto mx-lg-auto">
        <div class="content d-flex flex-column gap-40">
          <div class="d-flex flex-column gap-20">
            <h2>Não desperdice o seu tempo.</h2>
            <p>Vem fazer parte das mais de 140 empresas que tiveram seus lucros impulsionados através da tecnologia personalizada Vencode.</p>
          </div>
          <primary-button url="https://api.whatsapp.com/send?phone=558001912490&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento!"/>
          <div class="d-flex gap-16 align-items-center">
            <span>Nos veja em</span>
            <a href="https://www.facebook.com/vencodesoft">
              <facebook-icon/>
            </a>
            <a href="">
              <instagram-icon/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-cta{
  background: var(--c4);
}
*{
  color: var(--c1);
}
  .cta{
    background-size: 100%;
    background: linear-gradient(180deg, rgba(3, 3, 3, 0.00) 30%, #030303 40%), url("@/assets/images/46ab588ec9764830a7d66106a0f46a18.gif") no-repeat top center;
  }
  .content{
    width: 419px;
    max-width: 95vw;
  }
  h2{
    font-size: 57px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
  @media (min-width: 997px) {
    .cta{
      height: 850px;
      background: linear-gradient(180deg, rgba(3, 3, 3, 0.00) 60%, #030303 90%), url("@/assets/images/46ab588ec9764830a7d66106a0f46a18.gif") no-repeat top center;
      background-size: cover;
    }
  }
</style>