<script>
  import PrimaryButton from "@/components/shared/Button.vue";
  import CocacolaIcon from "@/components/icon/cocacola-icon.vue";
  import EletroluxIcon from "@/components/icon/eletrolux-icon.vue";
  import SeniorIcon from "@/components/icon/senior-icon.vue";
  import UberIcon from "@/components/icon/uber-icon.vue";

  export default {
    name: 'AboutComp',
    components: {UberIcon, SeniorIcon, EletroluxIcon, CocacolaIcon, PrimaryButton},
    data() {
      return {
        height: 'auto'
      }
    },
    methods: {
      updateHeight() {
        this.$nextTick(() => {
          const elemento = this.$el.querySelector('.box')
          if (elemento) {
            this.height = `${elemento.offsetWidth}px`;
          }
        });
      }
    },
    mounted() {
      this.updateHeight();
      window.addEventListener('resize', this.updateHeight);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateHeight);
    }
  }
</script>

<template>
  <div class="col-lg-8 py-80 gap-80  d-flex flex-column align-items-lg-center m-auto gap-40 justify-content-lg-between about" id="about">
    <div class="d-flex flex-column flex-lg-row gap-40 gap-lg-160">
      <div class="px-20 gap-lg-40 px-lg-0 col-lg-5 d-flex justify-content-end justify-content-lg-start align-items-end align-items-lg-start flex-column">
        <div class="gap-lg-16">
          <div class="d-flex align-items-center gap-20 flex-row-reverse flex-lg-row">
          <h2>Sobre nós</h2>
          <svg width="73" height="5" viewBox="0 0 73 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.724243" width="73" height="3.99566" fill="#174EDD"/>
          </svg>
          </div>
          <p class="text-end text-lg-start ">Com tecnologia de ponta, criamos softwares personalizados para impulsionar o seu sucesso e aumentar os seus lucros.</p>
        </div>
        <primary-button url=""/>
      </div>
      <div class="col-lg-6 d-flex flex-column gap-20 text-center">
        <div class="d-flex justify-content-center row gap-20 w-100 m-auto">
          <div class="px-lg-60 py-lg-80 d-flex align-items-center justify-content-center flex-column box" :style="{ height }">
            <h3>+192</h3>
            <h4>projetos realizados</h4>
          </div>
          <div class="px-lg-60 py-lg-80 d-flex align-items-center justify-content-center flex-column box" :style="{ height }">
            <h3>+9,6</h3>
            <h4>milhões de lucro</h4>
          </div>
        </div>
        <div class="d-flex justify-content-center row gap-20 w-100 m-auto">
          <div class="px-lg-60 py-lg-80 d-flex align-items-center justify-content-center flex-column box" :style="{ height }">
            <h3>+140</h3>
            <h4>empresas satisfeitas</h4>
          </div>
          <div class="px-lg-60 py-lg-80 d-flex align-items-center justify-content-center flex-column box" :style="{ height }">
            <h3>+8</h3>
            <h4>anos de experiência</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column gap-40">
      <h6 class="text-center">Empresas que confiam</h6>
      <div class="col-lg-10 d-flex flex-column flex-lg-row gap-80 align-items-center m-auto">
        <cocacola-icon/>
        <eletrolux-icon/>
        <senior-icon/>
        <uber-icon/>
      </div>
    </div>
  </div>
</template>

<style scoped>
h6{
  color: var(--c5);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.about{
  max-width: 100vw;
}
  .box{
    border-radius: 8px;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    width: calc(50% - 10px);
    max-width: 243px;
  }

  @media (max-width: 996px) {
    .box{
      width: calc(50% - 10px)
    };
  }
</style>