<script>
  export default {
    name: 'FeedbackComp'
  }
</script>

<template>
  <div class="d-flex flex-column py-80 gap-40 gap-lg-80 feedback" id="feedbacks">
    <div class="d-flex flex-column gap-20 col-11 col-lg-8 m-auto">
      <div class="d-flex">
        <h2>Feed<br class="d-lg-none"/>back</h2>
        <img class="astronaut" src="@/assets/images/ee9a2e9f-72ad-4107-8a29-2a7cc31f9b8c%201.png" alt="">
      </div>
      <p class="col-lg-3">O que nosso clientes falam sobre nós e nossos códigos.</p>
    </div>
    <div class="d-flex flex-column flex-lg-row justify-content-lg-center gap-40 gap-lg-80">
      <div class="content d-flex flex-column justify-content-between m-auto m-lg-0">
        <div class="d-flex align-items-start gap-20 feedback-div">
          <img src="@/assets/images/feedback.svg" alt="">
          <p>“Encontrar um software que se alinhasse perfeitamente com nossos sistemas existentes era um desafio. Com a Vencode, finalmente temos uma integração fluida e ganhamos eficiência em todas as áreas.”</p>
        </div>
        <h6>Carlos Oliveira - Empresário</h6>
      </div>
      <div class="line"></div>
      <div class="content d-flex flex-column justify-content-between m-auto m-lg-0">
        <div class="d-flex align-items-start gap-20 feedback-div">
          <img src="@/assets/images/feedback.svg" alt="">
          <p>“O software desenvolvido pela equipe da Vencode foi um divisor de águas para nossa empresa.Agora, temos processos otimizados e uma visão mais clara de nossas operações. Recomendo fortemente!.”</p>
        </div>
        <h6>Ana Silva - Empresária</h6>
      </div>
      <div class="line"></div>
      <div class="content d-flex flex-column justify-content-between m-auto m-lg-0">
        <div class="d-flex align-items-start gap-20 feedback-div">
          <img src="@/assets/images/feedback.svg" alt="">
          <p>“O ROI do nosso investimento com o software foi surpreendente. Com processos mais eficientes e aprimoramento na análise de dados, nossa lucratividade aumentou significativamente.”</p>
        </div>
        <h6>João Lima - Diretor Financeiro</h6>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .content{
    width: 350px;
    height: 227px;
  }
  .line{
    width: 90%;
    margin: auto;
    height: 1px;
    background: var(--c5);
  }
  *{
    color: var(--c2);
  }
  .feedback{
    background: var(--c4);
  }
  .feedback-div p{
    color: var(--c2);
    font-style: italic;
    font-weight: 300;
    line-height: 120%
  }
  h2{
    font-size: 57px;
  }
  h6{
    color: var(--c1);
    font-weight: 400;
    line-height: 120%
  }
  .astronaut{
    height: 257px;
    position: absolute;
    left: 50%;

    animation: float 3s infinite ease;
  }
  @keyframes float {
    0% {
      transform:translate(-20%, -50%) translateY(80px)
    }

    50% {
      transform:translate(-20%, -50%) translateY(0px)
    }

    100% {
      transform:translate(-20%, -50%) translateY(80px)
    }
  }
  @media (min-width: 996px) {
    @keyframes float {
      0% {
        transform:translate(-50%, -50%) translateY(80px)
      }

      50% {
        transform:translate(-50%, -50%) translateY(-10px)
      }

      100% {
        transform:translate(-50%, -50%) translateY(80px)
      }
    }
    .astronaut{
      height: 375px;
      position: absolute;
      left: 50%;
      animation: float 3s infinite ease;
    }
    .line{
      margin: 0;
      width: 1px;
      height: 280px;
    }
  }
</style>