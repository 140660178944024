<script setup>

</script>

<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.1172 16.8589C32.1172 8.02289 24.9532 0.858887 16.1172 0.858887C7.28119 0.858887 0.117188 8.02289 0.117188 16.8589C0.117188 24.8455 5.96786 31.4642 13.6172 32.6642V21.4842H9.55452V16.8575H13.6172V13.3349C13.6172 9.32555 16.0065 7.10955 19.6612 7.10955C21.4105 7.10955 23.2425 7.42289 23.2425 7.42289V11.3602H21.2239C19.2359 11.3602 18.6159 12.5935 18.6159 13.8589V16.8589H23.0532L22.3439 21.4855H18.6159V32.6655C26.2665 31.4642 32.1172 24.8442 32.1172 16.8589Z" fill="#CED3DD"/>
  </svg>
</template>

<style scoped>

</style>