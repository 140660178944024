<template>
  <nav class="navbar navbar-expand-lg">
    <div class="d-lg-flex col-12 px-20 py-20 py-lg-0 col-lg-8 m-auto justify-content-around">
      <div class="d-flex justify-content-between p-20 px-2 px-lg-0">
        <a class="d-flex justify-content-center align-items-center"><vencode-icon/></a>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
            <rect width="26" height="2.37037" rx="1" fill="#F7F9FF"/>
            <rect y="8.37036" width="26" height="2.37037" rx="1" fill="#F7F9FF"/>
            <rect y="16.7407" width="26" height="2.37037" rx="1" fill="#F7F9FF"/>
          </svg>
        </button>
      </div>
      <div class="collapse navbar-collapse justify-content-center" id="navbarToggleExternalContent">
        <ul class="navbar-nav mb-2 mb-lg-0 gap-40 my-3 my-lg-0">
          <li class="nav-item">
            <a class="nav-link py-lg-30 " href="#home" :class="{ active: activeLink === 'Inicio' }" aria-current="page" @click="setActiveLink('Inicio')">Inicio</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link py-lg-30" href="#about" :class="{ active: activeLink === 'Sobre nós' }" @click="setActiveLink('Sobre nós')">Sobre nós</a>
          </li>
          <li class="nav-item">
            <a class="nav-link py-lg-30 " href="#feedbacks" :class="{ active: activeLink === 'Feedbacks' }" @click="setActiveLink('Feedbacks')">Feedbacks</a>
          </li>
          <li class="nav-item">
            <a class="nav-link py-lg-30 " href="#doubt" :class="{ active: activeLink === 'Duvidas' }" @click="setActiveLink('Duvidas')">Duvidas</a>
          </li>
        </ul>
        <div class="d-flex d-lg-none mb-3 mb-lg-0 py-20 button-div gap-3">
          <PrimaryButton url="https://api.whatsapp.com/send?phone=558001912490&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento!"/>
        </div>
      </div>
      <div class="d-none d-lg-flex mb-3 mb-lg-0 py-20 button-div gap-3">
        <PrimaryButton url="https://api.whatsapp.com/send?phone=558001912490&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento!"/>
      </div>
    </div>
  </nav>
</template>

<script>
import PrimaryButton from "@/components/shared/Button.vue";
import VencodeIcon from "@/components/icon/vencode-icon.vue";

export default {
  name: "Nav-bar",
  components: {VencodeIcon, PrimaryButton},
  data() {
    return {
      activeLink: 'Inicio',
      id: null,
      isNavCollapsed: true,
      direction: true,
      isScrolled: false
    };
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link;
    }
  },
  created() {
    this.id = this.$route.params.id ? this.$route.params.id : 'H';
  }
}
</script>
<style scoped>
a{
  cursor: pointer;
}
* {
  color: var(--c1);
}
.navbar{
  padding: 0;
}
.navbar-nav{
  margin: 0;
}
nav{
  background: var(--c4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.products:not(.scrolled) .navbar-brand img{
  filter: brightness(0%);
}
.nav-link.active {
  color: var(--white);
}
@media screen and (min-width: 992px) {
  .nav-link.active{
    border-bottom: 4px solid #D9D9D9;
  }
}
@media screen and (max-width: 992px) {

  a{
    text-align: center !important;
  }
  .button-div{
    margin-top: 3rem;
    justify-content: center;
  }
}
</style>
