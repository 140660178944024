<script>

import CardShared from "@/components/shared/Card.vue";


export default {
  name: 'Products-comp',
  components: {CardShared},
}

</script>

<template>
  <div class="col-9 col-lg-8 m-auto d-flex flex-column align-items-center gap-lg-80 gap-40 py-40 py-lg-80">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28" fill="none">
      <path d="M8.00793 27.5365L13.5967 22.015C14.1396 21.4471 14.1396 20.5321 13.5648 19.9957C12.9899 19.4593 12.0957 19.4593 11.5528 19.9957L8.45504 23.0562L8.45504 1.4198C8.45504 0.631024 7.78438 -2.71693e-07 6.98599 -3.06592e-07C6.18759 -3.41491e-07 5.54887 0.631024 5.54887 1.4198L5.54887 23.0562L2.45108 19.9957C1.87624 19.4593 0.950094 19.4593 0.407183 20.0273C-0.135728 20.5952 -0.135728 21.4786 0.407183 22.015L5.99597 27.5365C6.53888 28.1044 7.43309 28.1675 8.00793 27.6311C8.00793 27.5996 8.03987 27.5996 8.00793 27.5365Z" fill="#174EDD"/>
    </svg>
    <div class="d-flex flex-column flex-lg-row justify-content-lg-around w-100 gap-40 gap-lg-0">
      <Card-shared text="Não importa o seu" red text-colored="problema" :contents="['Segurança', 'Baixa lucratividade', 'Falta de relatórios', 'Processos lentos', 'Outros']"/>
      <Card-shared text="Nós temos a" text-colored="solução!" :contents="['CRM', 'Automações', 'Inteligência artificial', 'Integrações', 'Sites']"/>
    </div>
  </div>
</template>

<style scoped>

</style>