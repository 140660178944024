<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="15" viewBox="0 0 80 15" fill="none">
    <path d="M11.4568 1.39884L7.42833 10.3956C7.36012 10.548 7.14074 10.5476 7.07322 10.3949L6.32448 8.70229C6.30302 8.65375 6.3029 8.59845 6.32425 8.54969L9.52861 1.24544C9.55947 1.17538 9.62931 1.13016 9.70669 1.13016H11.2795C11.4196 1.13016 11.5135 1.27234 11.4568 1.39884ZM5.74059 9.8803L6.6572 11.9656C6.67878 12.0146 6.67855 12.0702 6.65674 12.1191L5.91415 13.7772C5.84605 13.9293 5.62737 13.9293 5.55938 13.7772L0.0168038 1.39884C-0.0399259 1.27234 0.0539275 1.13016 0.194186 1.13016H1.76719C1.84445 1.13016 1.91441 1.17549 1.94504 1.24544L5.73676 9.88866L5.74059 9.8803Z" fill="white"/>
    <path d="M14.0956 12.391L12.6391 12.3914C12.5321 12.3914 12.4452 12.3057 12.4452 12.2001L12.4424 2.10167C12.4424 1.996 12.5292 1.91026 12.6362 1.91026L14.0926 1.90991C14.1997 1.9098 14.2866 1.99554 14.2866 2.10121L14.2894 12.1995C14.2894 12.3053 14.2026 12.3909 14.0956 12.391ZM14.294 12.05H19.633C19.7402 12.05 19.827 12.1357 19.827 12.2413V13.6786C19.827 13.7842 19.7402 13.87 19.633 13.87H12.6438C12.5366 13.87 12.4498 13.7842 12.4498 13.6786V12.2352C12.4498 12.1295 12.5366 12.0438 12.6438 12.0438H14.294V12.05ZM12.4498 8.21863V6.8073C12.4498 6.70164 12.5366 6.61601 12.6438 6.61601H18.711C18.8182 6.61601 18.9049 6.70164 18.9049 6.8073V8.21863C18.9049 8.3243 18.8182 8.41004 18.711 8.41004H12.6438C12.5366 8.41004 12.4498 8.3243 12.4498 8.21863ZM19.827 1.32148V2.75868C19.827 2.86434 19.7402 2.95009 19.633 2.95009H12.6438C12.5366 2.95009 12.4498 2.86434 12.4498 2.75868V1.32148C12.4498 1.21581 12.5366 1.13018 12.6438 1.13018H19.633C19.7402 1.13018 19.827 1.21581 19.827 1.32148Z" fill="white"/>
    <path d="M22.0977 5.20224L23.5543 6.64734C23.5904 6.68306 23.6107 6.7316 23.6107 6.7822V13.6787C23.6107 13.7842 23.5239 13.87 23.4169 13.87H21.9602C21.8531 13.87 21.7664 13.7842 21.7664 13.6787V5.33721C21.7664 5.16652 21.9756 5.08123 22.0977 5.20224ZM32.5248 1.32156V13.7046C32.5248 13.876 32.314 13.961 32.1924 13.8385L21.8217 3.39264C21.7862 3.3568 21.7664 3.30872 21.7664 3.25869V1.29717C21.7664 1.12556 21.9776 1.04073 22.0991 1.16369L30.3477 9.51395C30.4692 9.6369 30.6805 9.55207 30.6805 9.38035V1.32156C30.6805 1.21577 30.7674 1.13014 30.8744 1.13014H32.331C32.438 1.13014 32.5248 1.21577 32.5248 1.32156Z" fill="white"/>
    <path d="M44.8831 11.0676L45.4958 12.395C45.5312 12.4717 45.513 12.5634 45.4487 12.6189C44.3782 13.5425 42.8777 14.0216 41.5209 14.0216C37.8129 14.0216 34.7969 11.1021 34.7969 7.5001C34.7969 3.89806 37.8129 0.978559 41.5209 0.978559C42.8777 0.978559 44.3782 1.43969 45.4486 2.36218C45.513 2.4177 45.5312 2.50963 45.4958 2.58633L44.8818 3.91615C44.8275 4.03395 44.6717 4.06658 44.5759 3.97854C43.9764 3.42777 43.3082 3.062 42.4817 2.89772C39.666 2.33791 36.8949 4.27425 36.6581 7.09907C36.4251 9.8773 38.6394 12.2016 41.4056 12.2016C42.7611 12.2016 43.7357 11.7933 44.5755 11.0068C44.6712 10.9171 44.8283 10.9492 44.8831 11.0676Z" fill="white"/>
    <path d="M53.2407 14.0216C49.8407 14.0045 47.0558 11.457 46.7048 8.19677C46.6927 8.08424 46.7852 7.98647 46.8999 7.98705L48.3664 7.99449C48.4635 7.99483 48.543 8.06672 48.5563 8.16163C48.8762 10.4491 50.8352 12.1893 53.2501 12.2016C55.6648 12.2137 57.6417 10.4932 57.9852 8.20925C57.9995 8.11446 58.0796 8.04337 58.1767 8.04383L59.6432 8.05127C59.7581 8.05184 59.8495 8.15053 59.8363 8.26295C59.4515 11.5195 56.6404 14.0386 53.2407 14.0216ZM59.6487 7.0127L58.1822 7.00537C58.0851 7.00491 58.0056 6.93302 57.9923 6.83811C57.6724 4.55078 55.7136 2.81043 53.2987 2.7983C50.8838 2.78616 48.9069 4.5067 48.5634 6.7906C48.5492 6.88539 48.469 6.95637 48.3719 6.95591L46.9054 6.94859C46.7905 6.94801 46.6991 6.84933 46.7123 6.7368C47.0971 3.48049 49.9082 0.961216 53.3082 0.978274C56.7079 0.995446 59.4928 3.543 59.8438 6.80297C59.8559 6.91562 59.7634 7.01338 59.6487 7.0127Z" fill="#B3B3B3"/>
    <path d="M71.1432 7.50006C71.1432 11.0263 68.7609 13.87 64.5536 13.87H60.886C60.7789 13.87 60.692 13.7843 60.692 13.6786V12.2413C60.692 12.1356 60.7789 12.0499 60.886 12.0499H65.0338C67.5506 12.0499 69.2989 10.0214 69.2989 7.49983C69.2989 4.97861 67.5506 2.95 65.0338 2.95H60.886C60.7789 2.95 60.692 2.86437 60.692 2.75859V1.32151C60.692 1.21573 60.7789 1.13009 60.886 1.13009H64.5536C68.8569 1.13009 71.1433 3.97369 71.1432 7.50006Z" fill="white"/>
    <path d="M74.2728 13.1805H72.8164C72.7093 13.1805 72.6226 13.0949 72.6226 12.9891V1.88306C72.6226 1.77751 72.7093 1.69176 72.8164 1.69176H74.2728C74.3799 1.69176 74.4668 1.77751 74.4668 1.88306V12.9891C74.4668 13.0949 74.3799 13.1805 74.2728 13.1805ZM74.467 12.0499H79.806C79.9131 12.0499 80 12.1357 80 12.2413V13.6786C80 13.7842 79.9131 13.8699 79.806 13.8699H72.8168C72.7096 13.8699 72.6228 13.7842 72.6228 13.6786V12.2351C72.6228 12.1295 72.7096 12.0437 72.8168 12.0437H74.467V12.0499ZM72.6228 8.21857V6.80724C72.6228 6.70158 72.7096 6.61595 72.8168 6.61595H78.8839C78.991 6.61595 79.0778 6.70158 79.0778 6.80724V8.21857C79.0778 8.32423 78.991 8.40998 78.8839 8.40998H72.8168C72.7096 8.40998 72.6228 8.32423 72.6228 8.21857ZM80 1.32153V2.75861C80 2.86439 79.9131 2.95003 79.806 2.95003H72.8168C72.7096 2.95003 72.6228 2.86439 72.6228 2.75861V1.32153C72.6228 1.21575 72.7096 1.13012 72.8168 1.13012H79.806C79.9131 1.13012 80 1.21575 80 1.32153Z" fill="white"/>
  </svg>
</template>

<style scoped>

</style>