<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="177" height="29" viewBox="0 0 177 29" fill="none">
    <path d="M25.3483 0.930826L16.4352 20.8361C16.2843 21.1732 15.7989 21.1725 15.6495 20.8346L13.9929 17.0897C13.9454 16.9823 13.9452 16.86 13.9924 16.7521L21.082 0.591419C21.1503 0.436407 21.3048 0.336355 21.476 0.336355H24.9558C25.2659 0.336355 25.4735 0.650942 25.3483 0.930826ZM12.7011 19.6961L14.7291 24.3097C14.7768 24.4181 14.7763 24.5412 14.728 24.6494L13.085 28.318C12.9344 28.6544 12.4505 28.6544 12.3001 28.318L0.0371785 0.930826C-0.0883361 0.650942 0.119315 0.336355 0.429636 0.336355H3.90991C4.08085 0.336355 4.23563 0.436659 4.30339 0.591419L12.6926 19.7145L12.7011 19.6961Z" fill="white"/>
    <path d="M31.1862 25.2502L27.9639 25.251C27.727 25.251 27.5347 25.0615 27.5347 24.8277L27.5286 2.48504C27.5286 2.25125 27.7206 2.06153 27.9575 2.06153L31.1798 2.06078C31.4167 2.06052 31.6089 2.25024 31.6089 2.48402L31.6151 24.8265C31.6151 25.0605 31.4231 25.25 31.1862 25.2502ZM31.6254 24.4957H43.4379C43.675 24.4957 43.867 24.6854 43.867 24.9189V28.099C43.867 28.3327 43.675 28.5225 43.4379 28.5225H27.9742C27.737 28.5225 27.545 28.3327 27.545 28.099V24.9055C27.545 24.6717 27.737 24.482 27.9742 24.482H31.6254V24.4957ZM27.545 16.0188V12.8963C27.545 12.6625 27.737 12.473 27.9742 12.473H41.3978C41.635 12.473 41.827 12.6625 41.827 12.8963V16.0188C41.827 16.2526 41.635 16.4423 41.3978 16.4423H27.9742C27.737 16.4423 27.545 16.2526 27.545 16.0188ZM43.867 0.758867V3.93867C43.867 4.17245 43.675 4.36217 43.4379 4.36217H27.9742C27.737 4.36217 27.545 4.17245 27.545 3.93867V0.758867C27.545 0.525082 27.737 0.335621 27.9742 0.335621H43.4379C43.675 0.335621 43.867 0.525082 43.867 0.758867Z" fill="white"/>
    <path d="M48.8911 9.34587L52.114 12.5431C52.1938 12.6222 52.2387 12.7296 52.2387 12.8415V28.0999C52.2387 28.3335 52.0467 28.5232 51.8098 28.5232H48.587C48.3501 28.5232 48.1581 28.3335 48.1581 28.0999V9.6445C48.1581 9.26684 48.6211 9.07814 48.8911 9.34587ZM71.9612 0.759851V28.1574C71.9612 28.5366 71.4948 28.7246 71.2258 28.4535L48.2805 5.34212C48.202 5.26283 48.1581 5.15645 48.1581 5.04577V0.705898C48.1581 0.326218 48.6255 0.138529 48.8942 0.410563L67.1444 18.8855C67.4131 19.1576 67.8806 18.9699 67.8806 18.5899V0.759851C67.8806 0.52581 68.0728 0.336349 68.3097 0.336349H71.5323C71.7692 0.336349 71.9612 0.52581 71.9612 0.759851Z" fill="white"/>
    <path d="M99.3038 22.323L100.66 25.2599C100.738 25.4296 100.698 25.6325 100.555 25.7553C98.1868 27.7989 94.8669 28.8589 91.8651 28.8589C83.6612 28.8589 76.9882 22.3995 76.9882 14.43C76.9882 6.46047 83.6612 0.00107765 91.8651 0.00107765C94.8669 0.00107765 98.1868 1.02133 100.555 3.06234C100.698 3.18518 100.738 3.38857 100.66 3.55828L99.301 6.50049C99.1809 6.76113 98.8362 6.83331 98.6241 6.63853C97.2979 5.41996 95.8195 4.6107 93.9909 4.24723C87.7611 3.00864 81.6301 7.29278 81.106 13.5427C80.5906 19.6895 85.4898 24.8321 91.61 24.8321C94.609 24.8321 96.7653 23.9286 98.6234 22.1885C98.8351 21.9902 99.1827 22.0611 99.3038 22.323Z" fill="white"/>
    <path d="M117.795 28.8587C110.272 28.821 104.111 23.1845 103.334 15.9713C103.307 15.7223 103.512 15.506 103.766 15.5073L107.011 15.5238C107.225 15.5245 107.401 15.6836 107.431 15.8936C108.138 20.9545 112.473 24.8048 117.816 24.8319C123.158 24.8587 127.532 21.0521 128.292 15.9989C128.324 15.7892 128.501 15.6319 128.716 15.6329L131.96 15.6494C132.215 15.6506 132.417 15.869 132.388 16.1177C131.536 23.3228 125.317 28.8964 117.795 28.8587ZM131.973 13.3515L128.728 13.3353C128.513 13.3343 128.337 13.1752 128.308 12.9653C127.6 7.90454 123.266 4.05403 117.923 4.02718C112.58 4.00033 108.206 7.80702 107.446 12.8602C107.415 13.0699 107.237 13.2269 107.023 13.2259L103.778 13.2097C103.524 13.2084 103.322 12.9901 103.351 12.7411C104.202 5.53654 110.422 -0.0373592 117.944 0.000379562C125.466 0.0383739 131.628 5.67483 132.404 12.8875C132.431 13.1367 132.226 13.3531 131.973 13.3515Z" fill="#B3B3B3"/>
    <path d="M157.404 14.4289C157.404 22.2307 152.133 28.5225 142.824 28.5225H134.71C134.473 28.5225 134.281 28.3327 134.281 28.099V24.9189C134.281 24.6851 134.473 24.4954 134.71 24.4954H143.887C149.455 24.4954 153.324 20.0074 153.324 14.4284C153.324 8.85021 149.455 4.36191 143.887 4.36191H134.71C134.473 4.36191 134.281 4.17245 134.281 3.93841V0.758863C134.281 0.524824 134.473 0.335363 134.71 0.335363H142.824C152.346 0.335363 157.404 6.62683 157.404 14.4289Z" fill="white"/>
    <path d="M164.328 26.9972H161.106C160.869 26.9972 160.677 26.8077 160.677 26.5736V2.00151C160.677 1.76797 160.869 1.57826 161.106 1.57826H164.328C164.565 1.57826 164.758 1.76797 164.758 2.00151V26.5736C164.758 26.8077 164.565 26.9972 164.328 26.9972ZM164.758 24.4957H176.571C176.808 24.4957 177 24.6854 177 24.9192V28.0992C177 28.3327 176.808 28.5225 176.571 28.5225H161.107C160.87 28.5225 160.678 28.3327 160.678 28.0992V24.9055C160.678 24.6717 160.87 24.482 161.107 24.482H164.758V24.4957ZM160.678 16.0188V12.8963C160.678 12.6625 160.87 12.473 161.107 12.473H174.531C174.767 12.473 174.959 12.6625 174.959 12.8963V16.0188C174.959 16.2526 174.767 16.4423 174.531 16.4423H161.107C160.87 16.4423 160.678 16.2526 160.678 16.0188ZM177 0.759121V3.93867C177 4.1727 176.808 4.36217 176.571 4.36217H161.107C160.87 4.36217 160.678 4.1727 160.678 3.93867V0.759121C160.678 0.525082 160.87 0.335619 161.107 0.335619H176.571C176.808 0.335619 177 0.525082 177 0.759121Z" fill="white"/>
  </svg>
</template>

<style scoped>

</style>