<script>
  export default {
    name: 'senior-icon'
  }
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="66" viewBox="0 0 200 66" fill="none">
    <path d="M9.57937 7.63097C5.40103 11.8918 1.88511 15.9473 1.22269 17.2821C-0.407887 20.5162 -0.407887 25.8551 1.22269 29.0893C2.3437 31.2454 16.2545 46.03 17.2227 46.03C17.4265 46.03 17.987 45.2087 18.3947 44.1819C19.8214 40.7938 19.0571 39.2024 12.9934 32.9394C6.87873 26.6252 6.36918 25.9065 6.36918 23.0317C6.36918 20.5162 7.74498 18.5655 13.7068 12.6619C18.7004 7.73364 19.5666 5.88556 18.4966 2.60007C18.1399 1.57336 17.5794 0.597984 17.3246 0.495312C17.0189 0.392643 13.5539 3.62679 9.57937 7.63097Z" fill="#595D64"/>
    <path d="M59.5665 12.6107C54.2671 13.9968 51.2098 17.693 51.2098 22.6212C51.2098 27.9088 53.0442 29.9109 61.4518 33.8637C67.8212 36.8925 70.0633 38.8433 70.0633 41.4614C70.0633 45.2602 67.1588 47.5703 62.3181 47.5703C60.1779 47.5703 58.8531 47.1596 55.9486 45.6709C52.3308 43.8228 52.2289 43.8228 51.2098 44.7469C49.834 45.9789 49.9359 47.365 51.4646 48.905C54.6748 52.0878 61.9104 53.5252 67.2607 51.9338C72.8658 50.2911 76.2289 45.4142 75.4646 39.9213C74.8531 35.3011 72.1525 32.7343 64.2544 29.2948C57.6302 26.3687 55.541 24.0073 56.7639 20.7218C58.1397 17.0769 64.1525 16.1529 69.3499 18.8224C72.713 20.5164 73.9868 20.4138 74.4454 18.463C74.955 16.5636 74.2416 15.6909 71.0314 14.0481C67.9741 12.5081 62.5728 11.8407 59.5665 12.6107Z" fill="#595D64"/>
    <path d="M141.706 14.3053C140.585 15.8967 140.687 17.5908 141.91 18.8229C142.929 19.8496 145.222 20.1576 146.292 19.4389C148.178 18.2068 148.28 14.6647 146.496 13.6893C144.815 12.7652 142.522 13.0733 141.706 14.3053Z" fill="#595D64"/>
    <path d="M16.1526 20.6188C15.3373 22.4156 15.3883 25.239 16.2545 26.9331C16.6622 27.7032 19.108 30.3726 21.6558 32.9394C26.8532 38.073 27.7704 39.5104 27.7704 42.1798C27.7704 44.8493 26.8532 46.2867 21.6558 51.4202C16.5093 56.5538 15.5411 57.9912 15.5411 60.6093C15.5411 62.4061 16.4583 65.0242 17.0698 65.0242C17.2227 65.0242 20.8405 61.482 25.0698 57.1698C31.9488 50.1368 32.866 49.0588 33.5793 46.5433C34.5985 43.0525 34.5985 41.3071 33.5793 37.7649C32.866 35.2495 31.9997 34.2228 25.0698 27.1385C20.8405 22.8776 17.2227 19.3354 17.0698 19.3354C16.866 19.3354 16.4583 19.9001 16.1526 20.6188Z" fill="#595D64"/>
    <path d="M89.3758 25.1876C86.1147 26.0603 84.2803 27.5491 82.4969 30.7319C81.1211 33.2473 81.0192 33.8634 81.0192 38.5862C81.0192 42.8471 81.223 44.0792 82.1402 45.7732C84.4841 50.2394 87.9491 52.1902 93.2994 52.1902C97.4778 52.1902 100.076 51.4202 102.217 49.6747C103.49 48.5967 103.745 48.032 103.49 47.1593C102.828 45.1059 101.401 44.8492 98.7516 46.2866C94.2676 48.6994 89.0192 47.6726 87.1338 44.0278C86.7262 43.1551 86.3695 42.0257 86.3695 41.4097C86.3695 40.4343 86.6243 40.383 93.758 40.383C101.911 40.383 103.49 39.9723 103.949 37.7649C105.121 32.58 100.841 26.163 95.4395 24.9823C92.4332 24.3663 92.3313 24.3663 89.3758 25.1876ZM95.5415 29.8592C97.2739 30.7832 98.7516 33.2473 98.4969 34.8901C98.344 35.9681 98.1402 36.0195 92.4841 36.0195C86.9809 36.0195 86.6243 35.9681 86.4714 34.9927C86.2676 33.658 88.3058 30.5779 89.9364 29.7565C91.6688 28.8838 93.758 28.9351 95.5415 29.8592Z" fill="#595D64"/>
    <path d="M118.318 25.1882C115.108 26.0609 112.407 28.371 110.827 31.4512C109.655 33.7613 109.554 34.5827 109.401 42.3343C109.197 51.5748 109.35 52.1908 112.05 52.1908C114.751 52.1908 114.904 51.6774 114.904 43.3611C114.904 34.788 115.515 32.3752 118.114 30.5271C120.305 28.9357 124.28 28.9357 126.471 30.5271C129.069 32.3752 129.681 34.788 129.681 43.0017C129.681 47.0059 129.936 50.6507 130.19 51.2154C130.802 52.3448 133.655 52.6015 134.675 51.5748C135.592 50.6507 135.49 36.6361 134.522 33.4019C132.585 26.831 125.401 23.1861 118.318 25.1882Z" fill="#595D64"/>
    <path d="M162.802 24.9308C157.758 26.0089 154.395 29.4997 153.121 34.8386C151.796 40.3829 153.834 47.2618 157.707 50.0853C162.548 53.6275 170.446 53.2168 174.726 49.2126C177.223 46.8512 178.598 43.2063 178.598 38.8941C178.598 34.0173 177.834 31.6045 175.439 28.8837C172.586 25.5469 167.337 23.9555 162.802 24.9308ZM169.681 30.3211C172.025 31.8612 173.095 34.9413 172.891 39.3562C172.79 42.693 172.535 43.5657 171.312 45.1571C167.643 50.034 160.458 48.2372 158.726 42.0256C156.841 35.044 160.713 28.627 166.522 29.2944C167.592 29.397 169.019 29.8591 169.681 30.3211Z" fill="#595D64"/>
    <path d="M194.242 25.0344C190.267 25.9585 187.006 28.6793 185.426 32.4268C184.305 34.9936 183.745 49.3162 184.662 51.113C185.325 52.345 188.28 52.653 189.197 51.5236C189.503 51.2156 189.809 47.3655 189.911 42.9506C190.063 36.1229 190.216 34.7369 191.082 33.2481C192.458 30.938 194.242 29.6547 196.688 29.2953C198.573 28.9873 200 27.9606 200 26.9339C200 26.1638 197.961 24.4698 197.095 24.5211C196.586 24.5724 195.312 24.7778 194.242 25.0344Z" fill="#595D64"/>
    <path d="M142.573 25.3423C141.656 25.7017 141.605 50.6508 142.522 51.5748C143.286 52.3449 145.63 52.3449 146.395 51.5748C147.261 50.7021 147.261 26.4717 146.395 25.599C145.783 24.983 143.745 24.829 142.573 25.3423Z" fill="#595D64"/>
  </svg>
</template>

<style scoped>

</style>