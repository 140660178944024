<script>
export default {
  name: 'primary-Button',
  props:{
    url: null
  }
}

</script>

<template>
  <a :href="url"><button>Entrar em contato</button></a>
</template>

<style scoped>
  button{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid var(--c5);
    color: var(--c5);
    background-color: transparent;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  button:hover{
    background-color: var(--c5);
    color: var(--white);
  }
</style>