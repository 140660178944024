<script>
export default {
  name: 'Card-shared',
  props:{
    red: Boolean,
    text: String,
    textColored: String,
    contents: Array,
  }
}

</script>
<template>
  <div class="cards py-lg-40 px-lg-40 d-flex flex-column gap-40">
    <h2 class="text-center">{{text}} <span :style="red ? 'color: #C71111;' : 'color: #174EDD'">{{textColored}}</span></h2>
    <div class="d-flex flex-column gap-16">
      <div  v-for="(content, index) in contents" v-bind:key="index" class="d-flex flex-column align-items-center justify-content-center gap-20">
        <h3 class="text-center">{{content}}</h3>
        <svg v-if="index+1 < contents.length" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
          <circle cx="4" cy="4" r="4" fill="#174EDD"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped>
  *{
    margin: 0;
  }
  .cards{
    border: none;
  }
  h3{
    color: var(--c3);
    font-style: normal;
    font-weight: 400;
    line-height: 120%
  }
  @media (min-width: 996px){
    .cards{
      border-radius: 40px;
      border: 2px dashed var(--c4);
      width: 375px;
      height: 557px;
    }
  }
</style>