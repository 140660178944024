import { createRouter, createWebHistory } from 'vue-router'
import Home_page from "@/view/Home.vue";


const routes = [
    {
        path: '/:id?',
        name: 'Home-page',
        component: Home_page
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
})

export default router