<script>
  import FacebookIcon from "@/components/icon/facebook-icon.vue";
  import InstagramIcon from "@/components/icon/instagram-icon.vue";
  import VencodeBigIcon from "@/components/icon/vencode-big-icon.vue";

  export default {
    name: 'Footer',
    components: {VencodeBigIcon, InstagramIcon, FacebookIcon}
  }
</script>

<template>
  <footer>
    <div class="d-flex flex-column col-lg-10 m-auto py-40 py-lg-80 gap-80 align-items-center align-items-lg-start justify-content-lg-start">
      <vencode-big-icon/>
      <ul class="d-flex flex-column flex-lg-row gap-80 first-ul">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">Sobre Nós</a></li>
        <li><a href="#doubt">Dúvidas</a></li>
        <li><a href="https://api.whatsapp.com/send?phone=558001912490&text=Olá,%20gostaria%20de%20solicitar%20um%20orçamento!">Contato</a></li>
      </ul>
      <div class="d-flex flex-column gap-40 w-100">
        <p>A Vencode é uma empresa especializada em tecnologia da informação, desenhando e implantando softwares únicos e personalizados para cada cliente!</p>
        <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <span>&copy; 2023 Todos os Diretos reservados</span>
          <ul class="d-flex flex-column flex-lg-row gap-20">
            <li><a>Termos</a></li>
            <li><a>Privacidade</a></li>
            <li><a>Cookies</a></li>
          </ul>
          <div class="d-none d-lg-flex gap-16 icons justify-content-end">
            <a href="https://www.facebook.com/vencodesoft">
              <facebook-icon/>
            </a>
            <a href="https://www.instagram.com/vencodesoftware/">
              <instagram-icon/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
  a{
    text-decoration: none;
  }
  *{
    color: var(--c2)
  }
  footer{
    background: var(--c4);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul a{
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .first-ul a{
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .first-ul a:hover{
    font-weight: 500;
  }

  .icons{
    width: 309px;
  }

  @media (max-width: 996px) {
    *{
      text-align: center;
    }
  }
</style>